import {IDBObject} from './base.model';
import firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;

export interface IUser extends IDBObject {
  name?: string;
  email?: string;
  company?: string;
  roles?: UserRole[];
  dailyProductViewLimit?: number;
  lastLogin?: Timestamp;
}

export interface ILoginLog extends IDBObject {
  user?: IUser,
  userID?: string;
  timestamp?: Timestamp;
  ip?: string;
  country?: string;
  region?: string;
}

export enum UserRole {
  ADMIN = 'admin',
  PARTNER = 'partner',
  BBD_EMPLOYEE = 'bbdEmployee',
  APP_MANAGER = 'appManager',
  IDC_MANAGER = 'idcManager',
  SHOP_MANAGER = 'shopManager',
  QRCODE_MANAGER = 'qrcodeManager',
  USER_MANAGER = 'userManager',
}
