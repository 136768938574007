import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {UserRole} from "../../models/user.model";
//@ts-ignore
import Chart from 'chart.js';
import {ShopService} from "../../services/shop.service";
import {IProductView, IShopMetrics} from "../../models/shop.module";
import {Subject} from "rxjs";
import {randomRGB} from "../../shared/utils";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit, OnDestroy {

  @ViewChild('areaChart') areaChartRef: ElementRef;
  @ViewChild('pieChart') pieChartRef: ElementRef;
  @ViewChild('barChart') barChartRef: ElementRef;
  private pieChart: Chart;
  private areaChart: Chart;
  private barChart: Chart;

  metrics: IShopMetrics;
  productViews: IProductView[];

  userRoles: UserRole[] = [];


  productsViewedTableTrigger: Subject<any> = new Subject<any>();
  productsViewedTableOptions: DataTables.Settings = {
    destroy: true,
    pageLength: 100,
    order: [0, 'desc']
  };

  showContent = false;


  constructor(
    private authService: AuthService,
    private shopService: ShopService,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.spinner.show('pageSpinner');
    this.authService.getUserRoles().subscribe(roles => {
      this.userRoles = roles;
      if (this.userRoles.includes(UserRole.USER_MANAGER)) {
        this.shopService.getProductViews().subscribe(views => {
          this.productViews = views;
          this.productsViewedTableTrigger.next();
        })
      }
    })
    this.shopService.getShopMetrics().subscribe(metrics => {
      this.metrics = metrics;
      this.initLoginsByPartner();
      this.initProductsViewedByDate();
      this.initProductsViewedByPartner();
      this.spinner.hide('pageSpinner');
      this.showContent = true;
    })
  }

  ngOnDestroy(): void {
    this.productsViewedTableTrigger.unsubscribe();
  }

  initLoginsByPartner() {
    const mostActivePartners = this.metrics.loginMetrics.loginsByPartnerLast30Days.sort((a, b) => b.logins - a.logins);
    const sevenMostActive = mostActivePartners.slice(0, 7);

    const labels = sevenMostActive.map(a => a.partner);
    labels.push('Others');
    const data = sevenMostActive.map(a => a.logins);
    let rest = 0;
    for (let r of mostActivePartners.slice(7)) {
      rest += r.logins;
    }
    data.push(rest);
    this.pieChart = new Chart(this.pieChartRef.nativeElement, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: labels.map(a => randomRGB()),
          hoverBorderColor: "rgba(234, 236, 244, 1)",
        }],
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          caretPadding: 10,
        },
        legend: {
          display: true,
          position: 'top',
          labels: {
            padding: 10,
            usePointStyle: true,
          }
        },
        cutoutPercentage: 0,
      },
    });
  }

  initProductsViewedByDate() {

    this.areaChart = new Chart(this.areaChartRef.nativeElement, {
      type: 'bar',
      data: {
        labels: this.metrics.productMetrics.productViewsByDateLast30Days.map(a => a.dateString),
        datasets: [{
          label: "Views",
          tension: 0.3,
          backgroundColor: "rgba(78, 115, 223, 1)",
          borderColor: "rgba(78, 115, 223, 1)",
          color: "rgba(78, 115, 223, 1)",
          pointRadius: 3,
          pointBackgroundColor: "rgba(78, 115, 223, 1)",
          pointBorderColor: "rgba(78, 115, 223, 1)",
          pointHoverRadius: 3,
          pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
          pointHoverBorderColor: "rgba(78, 115, 223, 1)",
          pointHitRadius: 10,
          pointBorderWidth: 2,
          data: this.metrics.productMetrics.productViewsByDateLast30Days.map(a => a.views),
        }],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0
          }
        },
        scales: {
          //@ts-ignore
          xAxes: [{
            time: {
              unit: 'date'
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              maxTicksLimit: 7
            }
          }],
          //@ts-ignore
          yAxes: [{
            ticks: {
              maxTicksLimit: 5,
              padding: 10,
            },
            gridLines: {
              color: "rgb(234, 236, 244)",
              zeroLineColor: "rgb(234, 236, 244)",
              drawBorder: false,
              borderDash: [2],
              zeroLineBorderDash: [2]
            }
          }],
        },
        legend: {
          display: false
        },
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,

        }
      }
    });
  }

  initProductsViewedByPartner() {
    const sortedData = this.metrics.productMetrics.productViewsByPartnerLast30Days.sort((a, b) => b.views - a.views);
    this.barChart = new Chart(this.barChartRef.nativeElement, {
      type: 'bar',
      data: {
        labels: sortedData.map(a => a.partner),
        datasets: [{
          label: "Views",
          tension: 0.3,
          backgroundColor: "rgba(78, 115, 223, 1)",
          borderColor: "rgba(78, 115, 223, 1)",
          color: "rgba(78, 115, 223, 1)",
          pointRadius: 3,
          pointBackgroundColor: "rgba(78, 115, 223, 1)",
          pointBorderColor: "rgba(78, 115, 223, 1)",
          pointHoverRadius: 3,
          pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
          pointHoverBorderColor: "rgba(78, 115, 223, 1)",
          pointHitRadius: 10,
          pointBorderWidth: 2,
          data: sortedData.map(a => a.views),
        }],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0
          }
        },
        scales: {
          //@ts-ignore
          xAxes: [{
            time: {
              unit: 'date'
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
            }
          }],
          //@ts-ignore
          yAxes: [{
            ticks: {
              maxTicksLimit: 5,
              padding: 10,
            },
            gridLines: {
              color: "rgb(234, 236, 244)",
              zeroLineColor: "rgb(234, 236, 244)",
              drawBorder: false,
              borderDash: [2],
              zeroLineBorderDash: [2]
            }
          }],
        },
        legend: {
          display: false
        },
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,

        }
      }
    });
  }

  get UserRoles() {
    return UserRole;
  }

}
