import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireFunctionsModule} from '@angular/fire/functions';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireModule} from '@angular/fire';
import {DataTablesModule} from 'angular-datatables';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LayoutComponent} from './layout/layout.component';
import {AdminDashboardComponent} from './pages/dashboard/admin-dashboard.component';
import {LoginComponent} from './pages/login/login.component';
import {NgxSpinnerModule} from "ngx-spinner";
import {SharedModule} from "./shared/shared.module";


const firebaseConfig = {
  apiKey: 'AIzaSyDMN9Tj08SYR8PrC9_2r6n8Lb37uCVq4d8',
  authDomain: 'bbdplatform.firebaseapp.com',
  projectId: 'bbdplatform',
  storageBucket: 'bbdplatform.appspot.com',
  messagingSenderId: '810641540534',
  appId: '1:810641540534:web:152a81db4c28d41f8c6d17',
  measurementId: 'G-Z881CKSGXZ'
};


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    AdminDashboardComponent,
    LoginComponent,
  ],
  imports: [
    SharedModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    ToastrModule.forRoot(),
  ],
  providers: [],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
