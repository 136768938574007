<div [class]="divClass">
  <label>
    {{name}}
  </label>
  <select class="form-select" [formControl]="control">
    <option [ngValue]="''" disabled>
      {{'Choose Option'}}
    </option>
    <option *ngFor="let option of options" [ngValue]="option.value">
      {{option.name}}
    </option>
  </select>
  <div class="invalid-feedback" *ngIf="control.touched && control.invalid">
    <ng-container *ngFor="let error of errors">
      <div *ngIf="control.hasError(error.key)">
        {{error.message}}
      </div>
    </ng-container>
  </div>
</div>
