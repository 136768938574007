<!-- Page Wrapper -->
<div id="wrapper">
  <!-- Sidebar -->
  <ul
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <a
      class="sidebar-brand d-flex align-items-center justify-content-center"
      routerLink="/"
    >
      <img
        src="assets/img/logo_horizontal_white.png"
        alt="Admin Logo"
        class="img-fluid"
      />
      <!-- <div class="sidebar-brand-text mx-3">BBD Platform</div> -->
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <li
      class="nav-item"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a class="nav-link" routerLink="/">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span></a
      >
    </li>

    <!-- Nav Item - General -->
    <li class="nav-item" [needsRole]="UserRole.SHOP_MANAGER">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#generalCollapse"
        aria-expanded="true"
        aria-controls="generalCollapse"
      >
        <i class="fas fa-fw fa-ellipsis-h"></i>
        <span>General</span>
      </a>
      <div
        id="generalCollapse"
        class="collapse"
        aria-labelledby="generalHeading"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            routerLink="/general/languages"
            [needsRole]="UserRole.ADMIN"
            >Languages</a
          >
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            routerLink="/general/therapeutic-areas/"
            >Therapeutic Areas</a
          >
        </div>
      </div>
    </li>

    <!-- Nav Item - IDCs -->
    <li class="nav-item" [needsRole]="UserRole.IDC_MANAGER">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#idcCollapse"
        aria-expanded="true"
        aria-controls="idcCollapse"
      >
        <i class="fas fa-fw fa-capsules"></i>
        <span>IDCs</span>
      </a>
      <div
        id="idcCollapse"
        class="collapse"
        aria-labelledby="idcHeading"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            disabled
            routerLink="/idc/charts"
            >Charts</a
          >
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            disabled
            routerLink="/idc/substances"
            >Substances</a
          >
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            disabled
            routerLink="/idc/interactions"
            >Interactions</a
          >
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            disabled
            routerLink="/idc/interaction-types"
            >Interaction Types</a
          >
        </div>
      </div>
    </li>

    <!-- Nav Item - Shop -->
    <li class="nav-item" [needsRole]="UserRole.SHOP_MANAGER">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#shopCollapse"
        aria-expanded="true"
        aria-controls="shopCollapse"
      >
        <i class="fas fa-fw fa-dollar-sign"></i>
        <span>Shop</span>
      </a>
      <div
        id="shopCollapse"
        class="collapse"
        aria-labelledby="shopHeading"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            routerLink="/shop/mainCategories"
            >Products</a
          >
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            routerLink="/shop/clients"
            >Clients</a
          >
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            routerLink="/shop/incomingClientWorks"
            >Incoming Client Works</a
          >
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            routerLink="/shop/catalogues"
            >Catalogues</a
          >
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            routerLink="/shop/medicalArt"
            >Medical Art</a
          >
        </div>
      </div>
    </li>

    <!-- Nav Item - Apps -->
    <li class="nav-item" [needsRole]="UserRole.APP_MANAGER">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#appsCollapse"
        aria-expanded="true"
        aria-controls="appsCollapse"
      >
        <i class="fas fa-fw fa-gamepad"></i>
        <span>Apps</span>
      </a>
      <div
        id="appsCollapse"
        class="collapse"
        aria-labelledby="appsHeading"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            routerLink="/apps/requests"
            >Open Requests</a
          >
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            routerLink="/apps/closed-requests"
            >Closed Requests</a
          >
          <a
            class="collapse-item"
            [routerLinkActive]="['active']"
            routerLink="/apps/accesses"
            >Accesses</a
          >
          <div [needsRole]="UserRole.ADMIN">
            <hr />
            <a
              class="collapse-item"
              [routerLinkActive]="['active']"
              routerLink="/apps/poster-maker-apps"
              >Poster Maker apps</a
            >
            <a
              class="collapse-item"
              [routerLinkActive]="['active']"
              routerLink="/apps/poster-maker-apps-v2"
              >Poster Maker V2</a
            >
            <a
              class="collapse-item"
              [routerLinkActive]="['active']"
              routerLink="/apps/drive-apps"
              >Drive apps</a
            >
            <a
              class="collapse-item"
              [routerLinkActive]="['active']"
              routerLink="/apps/medcare-apps"
              >Medcare apps</a
            >
            <a
              class="collapse-item"
              [routerLinkActive]="['active']"
              routerLink="/apps/medcare-apps-v2"
              >Medcare V2</a
            >
            <hr />
            <a
              class="collapse-item"
              [routerLinkActive]="['active']"
              routerLink="/apps/instruction-manuals"
              >Instruction Manuals</a
            >
          </div>
        </div>
      </div>
    </li>

    <li
      class="nav-item"
      [needsRole]="UserRole.USER_MANAGER"
      [routerLinkActive]="['active']"
    >
      <a class="nav-link" routerLink="/apps/sold">
        <i class="fas fa-fw fa-rocket"></i>
        <span>Sold Apps</span></a
      >
    </li>

    <!-- Nav Item - QR Codes -->
    <li class="nav-item" [routerLinkActive]="['active']">
      <a class="nav-link" routerLink="/qrcodes">
        <i class="fas fa-fw fa-qrcode"></i>
        <span>QR Codes</span></a
      >
    </li>

    <!-- Nav Item - Users -->
    <li
      class="nav-item"
      [needsRole]="UserRole.USER_MANAGER"
      [routerLinkActive]="['active']"
    >
      <a class="nav-link" routerLink="/users">
        <i class="fas fa-fw fa-users"></i>
        <span>Users</span></a
      >
    </li>
  </ul>
  <!-- End of Sidebar -->

  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">
    <!-- Main Content -->
    <div id="content">
      <!-- Topbar -->
      <nav
        class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow"
      >
        <!-- Sidebar Toggle (Topbar) -->
        <button
          id="sidebarToggleTop"
          class="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i class="fa fa-bars"></i>
        </button>

        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">
          <!-- Nav Item - Alerts -->
          <li class="nav-item dropdown no-arrow mx-1">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="alertsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-bell fa-fw"></i>
              <span
                class="badge badge-danger badge-counter"
                *ngIf="alerts.length > 0"
                >{{ alerts.length > 3 ? "3+" : alerts.length }}</span
              >
            </a>
            <div
              class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="alertsDropdown"
            >
              <h6 class="dropdown-header">Alerts Center</h6>
              <button
                type="button"
                class="dropdown-item d-flex align-items-center"
                (click)="markAlertAsUnread(a)"
                *ngFor="let a of alerts | slice : 0 : 3"
              >
                <div class="mr-3">
                  <div class="icon-circle bg-{{ a.background }}">
                    <i class="fas {{ a.icon }} text-white"></i>
                  </div>
                </div>
                <div>
                  <div class="small text-gray-500">
                    {{ a.createdOn.toDate() | date }}
                  </div>
                  <span class="font-weight-bold">{{ a.text }}</span>
                </div>
              </button>
              <div class="text-center py-2" *ngIf="alerts.length === 0">
                You have no alerts at this time.
              </div>
              <button
                class="dropdown-item text-center small text-gray-500"
                type="button"
                (click)="clearAllAlerts()"
              >
                Clear All Alerts
              </button>
            </div>
          </li>

          <div class="topbar-divider d-none d-sm-block"></div>

          <!-- Nav Item - User Information -->
          <li class="nav-item dropdown no-arrow">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="mr-2 d-none d-inline text-gray-600 small">{{
                user?.email
              }}</span>
            </a>
            <!-- Dropdown - User Information -->
            <div
              class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <a
                class="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <i
                  class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"
                ></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <!-- End of Topbar -->

      <!-- Begin Page Content -->
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- End of Main Content -->

    <!-- Footer -->
    <footer class="sticky-footer bg-white">
      <div class="container my-auto">
        <div class="copyright text-center my-auto">
          <span>Copyright &copy; Built by Doctors 2022</span>
        </div>
      </div>
    </footer>
    <!-- End of Footer -->
  </div>
  <!-- End of Content Wrapper -->
</div>
<!-- End of Page Wrapper -->

<!-- Logout Modal-->
<div
  class="modal fade"
  id="logoutModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
        <button
          class="close"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Select "Logout" below if you are ready to end your current session.
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="logout()">
          Logout
        </button>
      </div>
    </div>
  </div>
</div>
