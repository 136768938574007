import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-field[name][control]',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.css'],
})
export class InputFieldComponent {
  @Input() name = 'Form Field';
  @Input() type:
    | 'text'
    | 'number'
    | 'checkbox'
    | 'date'
    | 'file'
    | 'textarea'
    | 'radio' = 'text';
  @Input() control!: FormControl;
  @Input() placeholder: string = '';
  @Input() disabled = false;
  @Input() errors?: { key: string; message: string }[];
  @Input() divClass = 'mb-3';
  @Input() rows = 3;
  @Input() accept = '*/*';
  @Input() fileDroppedFunction?: ($event: any) => void;
  @Input() radioName = '';
  @Input() radioOptions?: { label: string; value: string }[];

  defaultFileDrop($event: any) {
    const file = $event.target.files[0];
    this.control.setValue(file);
  }
}
