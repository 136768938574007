import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { InputFieldComponent } from '../input-field/input-field.component';

export type IControlData = {
  name: string;
  type: typeof InputFieldComponent.prototype.type | 'select';
  controlKey?: string;
  validators?: ValidatorFn[];
  initialValue?: any;
  errors?: { key: string; message: string }[];
};

@Component({
  selector: 'app-array-input[name][formArray][controlData]',
  templateUrl: './array-input.component.html',
  styleUrls: ['./array-input.component.css'],
})
export class ArrayInputComponent implements OnInit {
  @Input() name: string;
  @Input() formArray: FormArray;
  @Input() set controlData(data: IControlData | IControlData[]) {
    if (data instanceof Array) {
      this._controlData = data;
      this._formType = 'formGroup';
    } else {
      this._controlData = [data];
      this._formType = 'formControl';
    }
  }
  @Input() buttonText: string = '';

  _controlData: IControlData[];
  _formType: 'formGroup' | 'formControl';

  constructor() {}

  ngOnInit(): void {}

  add() {
    if (this._formType === 'formGroup') {
      const formGroup = new FormGroup(
        this._controlData.reduce((acc, cur) => {
          acc[cur.controlKey] = new FormControl(
            cur.initialValue === 0 ? cur.initialValue : cur.initialValue || '',
            cur.validators || []
          );
          return acc;
        }, {})
      );
      this.formArray.push(formGroup);
    } else {
      const controlData = this._controlData[0];
      this.formArray.push(
        new FormControl(
          controlData.initialValue || '',
          controlData.validators || []
        )
      );
    }
  }

  remove(index: number) {
    this.formArray.removeAt(index);
  }

  getControl(data: IControlData, c: any): FormControl {
    return this._formType === 'formControl' ? c : c.controls[data.controlKey];
  }
}
