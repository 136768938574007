import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {LayoutComponent} from './layout/layout.component';
import {AdminDashboardComponent} from './pages/dashboard/admin-dashboard.component';
import {PlatformGuard} from './platform-guard.service';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {
    path: '', component: LayoutComponent, canActivateChild: [PlatformGuard], children: [
      // General pages
      {path: '', component: AdminDashboardComponent},

      // Modules
      {path: 'idc', loadChildren: () => import('./modules/idc/idc.module').then(m => m.IdcModule)},
      {path: 'general', loadChildren: () => import('./modules/general/general.module').then(m => m.GeneralModule)},
      {path: 'shop', loadChildren: () => import('./modules/shop/shop.module').then(m => m.ShopModule)},
      {path: 'qrcodes', loadChildren: () => import('./modules/qrcodes/qrcodes.module').then(m => m.QrcodesModule)},
      {path: 'users', loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)},
      {path: 'apps', loadChildren: () => import('./modules/apps/apps.module').then(m => m.AppsModule)}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
