import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-form[form]',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  @Input() header: string;
  @Input() form: FormGroup;
  @Input() canSave: () => boolean;
  @Output('submit') submit = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  canSaveForm() {
    return this.canSave ? this.canSave() : this.form.valid;
  }

  previousState() {
    window.history.back();
  }

}
