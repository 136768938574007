<!-- Page Heading -->
<div style="position: relative; padding-top: 750px;" [hidden]="showContent">
  <ngx-spinner
    [fullScreen]="false"
    name="pageSpinner"
    type="ball-clip-rotate"
    color="#4e73df"
    bdColor="#f8f9fc"
    style="padding-top: 50px"
  >
  </ngx-spinner>
</div>
<div [hidden]="!showContent">
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
  </div>

  <!-- Content Row -->
  <div class="row">

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                Product Views (Last 30 days)
              </div>
              <div
                class="h5 mb-0 font-weight-bold text-gray-800">{{metrics?.productMetrics?.productViewsLast30Days}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-file fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-success shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                Client Work Views (Last 30 days)
              </div>
              <div
                class="h5 mb-0 font-weight-bold text-gray-800">{{metrics?.clientMetrics?.clientWorkViewsLast30Days}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-briefcase fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-info shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                Logins (Last 30 days)
              </div>
              <div class="h5 mb-0 font-weight-bold text-gray-800">{{metrics?.loginMetrics?.loginsLast30Days}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-users fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pending Requests Card Example -->
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-warning shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                Total Views (Last 30 days)
              </div>
              <div
                class="h5 mb-0 font-weight-bold text-gray-800">{{metrics?.productMetrics?.productViewsLast30Days + metrics?.clientMetrics?.clientWorkViewsLast30Days}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-eye fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Content Row -->
  <div class="row card-deck">
    <div class="card shadow mb-4 col-xl-8 col-lg-7 px-0">
      <!-- Area Chart -->
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Products Viewed by Date</h6>
      </div>
      <div class="card-body">
        <div class="chart-area">
          <canvas #areaChart id="myAreaChart"></canvas>
        </div>
      </div>
    </div>

    <!-- Donut Chart -->
    <div class="card shadow mb-4 col-xl-4 col-lg-5 px-0">
      <!-- Card Header - Dropdown -->
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Most Active Partners (By Number of Logins)</h6>
      </div>
      <!-- Card Body -->
      <div class="card-body">
        <div class="chart-pie">
          <canvas #pieChart id="myPieChart"></canvas>
        </div>
      </div>
    </div>
  </div>

  <div class="row pt-3">
    <!-- Bar Chart -->
    <div class="col-12 mb-4">
      <div class="card shadow">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Products Viewed by Partner</h6>
        </div>
        <div class="card-body">
          <div class="chart-bar">
            <canvas #barChart id="myBarChart"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" [needsRole]="UserRoles.USER_MANAGER">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Latest product views</h6>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table datatable [dtTrigger]="productsViewedTableTrigger" [dtOptions]="productsViewedTableOptions"
                   class="table table-bordered" width="100%" cellspacing="0">
              <thead>
              <tr>
                <th>View Date</th>
                <th>User</th>
                <th>Partner</th>
                <th>Product</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let v of productViews">
                <td>
                <span
                  style="display: none">{{v.createdOn.toDate() | date : 'yyyy/MM/dd'}}</span>{{ v.createdOn.toDate() | date : 'dd/MM/y HH:mm:ss'}}
                </td>
                <td>{{v.user?.name}}</td>
                <td>{{v.user?.company}}</td>
                <td>{{ v.product.name }}</td>
                <td>
                  <a class="btn btn-info ml-1" href="{{v.product.fileURL}}" target="_blank">
                    <i class="fa fa-eye"></i>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

