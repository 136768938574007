import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import firebase from 'firebase';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {UserRole} from '../models/user.model';
import User = firebase.User;

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  public user: User;
  public user$: Observable<User>;
  public userRoles: UserRole[] = [];

  constructor(private afAuth: AngularFireAuth, private router: Router, private db: AngularFirestore) {
    this.user$ = this.afAuth.authState;
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.user = user;
        this.user$ = this.afAuth.authState;
        this.getUserRoles().subscribe(roles => {
          this.userRoles = roles;
        })
      }
    });
  }


  // Sign in with email/password
  public async signIn(email, password): Promise<boolean> {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        this.user = (result.user);
        this.user$ = this.afAuth.authState;
        const token = await result.user.getIdTokenResult();
        this.userRoles = this.convertClaimsToRoles(token.claims);
        return true;
      }).catch((error) => {
        window.alert(error.message);
        return false;
      });
  }

  public logout(): void {
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/login']);
    });
  }

  public isAdmin(): Observable<boolean> {
    return this.afAuth.idTokenResult.pipe(map(token => {
      return token && token.claims[UserRole.ADMIN] === true;
    }));
  }

  public isBBDEmployee(): Observable<boolean> {
    return this.afAuth.idTokenResult.pipe(map(token => {
      return token && token.claims[UserRole.BBD_EMPLOYEE] === true;
    }));
  }

  private convertClaimsToRoles(claims: { [p: string]: any }): UserRole[] {
    const roles = [];
    for (const key in claims) {
      if (Object.values<string>(UserRole).includes(key) && claims[key] === true) {
        roles.push(key);
      }
    }
    return roles;
  }

  public getUserRoles() {
    return this.afAuth.idTokenResult.pipe(map(token => {
      if (token && token.claims) {
        return this.convertClaimsToRoles(token.claims);
      } else {
        return [];
      }
    }));
  }

}
