import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    if (this.authService.user$) {
      this.authService.user$.subscribe(user => {
        if (user) {
          this.router.navigate(['/']);
        }
      });
    }
    this.createForm();
  }

  private createForm(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  public login(): void {
    this.authService.signIn(this.loginForm.get('email')?.value, this.loginForm.get('password')?.value).then(success => {
      if (success) {
        this.router.navigate(['/']);
      }
    });
  }

}
