import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.css']
})
export class SelectFieldComponent {
  @Input() name!: string;
  @Input() control!: FormControl;
  @Input() options!: { value: any, name: string }[];
  @Input() singleSelection: boolean = true;
  @Input() placeholder: string = '';
  @Input() divClass: string = 'mb-3';
  @Input() errors?: { key: string, message: string }[];

}
