import {AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../services/auth.service";
import {UserRole} from "../models/user.model";
import {Observable, Subscription} from "rxjs";

@Directive({ selector: '[needsRole]' })
export class NeedsRoleDirective implements OnInit, OnDestroy {
  constructor(
    private el: ElementRef,
    private auth: AuthService
  ) {
  }

  @Input() needsRole: UserRole;

  subscriptions: Subscription[] = [];

  public ngOnInit() {
    const originalDisplay = this.el.nativeElement.display;
    const sub = this.auth.getUserRoles().subscribe(roles => {
      if(roles.includes(this.needsRole) || roles.includes(UserRole.ADMIN)) {
        // If condition is true show element
        this.el.nativeElement.style.display = originalDisplay;
      } else {
        // Else hide element
        this.el.nativeElement.style.display = 'none';
      }
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    for(let s of this.subscriptions) {
      s.unsubscribe();
    }
  }

}
