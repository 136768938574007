import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {AngularFirestore} from '@angular/fire/firestore';
import firebase from 'firebase';
import DocumentReference = firebase.firestore.DocumentReference;
import Timestamp = firebase.firestore.Timestamp;
import {ITherapeuticArea} from '../models/therapeutic-area.model';
import {IDBObject} from '../models/base.model';

@Injectable({
  providedIn: 'root'
})
export class BaseDbService {

  static DELETED_OBJECTS_LOG_KEY = 'deletedObjects';

  constructor(private authService: AuthService,
              private db: AngularFirestore) {
  }

  public async createObject(object: any, collection: string): Promise<DocumentReference> {
    const time = Timestamp.now();
    object.createdBy = this.authService.user.uid;
    object.createdByName = this.authService.user.displayName;
    object.updatedBy = this.authService.user.uid;
    object.updatedByName = this.authService.user.displayName;
    object.createdOn = time;
    object.updatedOn = time;
    // Make sure undefined is not set, id is assigned automatically then updated in object
    delete object.id;
    const docRef = await this.db.collection(collection).add(object);
    await docRef.update({id: docRef.id});
    return docRef;
  }

  public async createObjectWithExistingID(object: any, collection: string): Promise<void> {
    const time = Timestamp.now();
    object.createdBy = this.authService.user.uid;
    object.createdByName = this.authService.user.displayName;
    object.updatedBy = this.authService.user.uid;
    object.updatedByName = this.authService.user.displayName;
    object.createdOn = time;
    object.updatedOn = time;
    await this.db.collection(collection).doc(object.id).set(object);
  }

  public updateObject(object: any, collection: string): Promise<void> {
    const time = Timestamp.now();
    object.updatedBy = this.authService.user.uid;
    object.updatedByName = this.authService.user.displayName;
    object.updatedOn = time;
    return this.db.collection(collection).doc(object.id).update(object);
  }

  public deleteObject(object: any, collection: string): Promise<void> {
    return this.db.collection(collection).doc(object.id).delete().then(async v => {
      const time = Timestamp.now();
      object.deletedBy = this.authService.user.uid;
      object.deletedByName = this.authService.user.displayName;
      object.deletedOn = time;
      object.deletedFrom = collection;
      await this.db.collection(BaseDbService.DELETED_OBJECTS_LOG_KEY).add(object);
    });
  }

  public updateLocalList(array: any[], obj: IDBObject, toDelete?: boolean): void {
    // Only add or modify if a fetch has been made
    if(array.length > 0){
      const index = array.findIndex(o => o.id === obj.id);
      // To delete or to replace
      if (toDelete || index !== -1) {
        array.splice(index, 1);
      }
      if (!toDelete) {
        array.push(obj);
      }
    }
  }

}
