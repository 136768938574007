<div class="container">
  <div class="card o-hidden border-0 shadow-lg my-5">
    <div class="card-body p-0">
      <div class="offset-3 col-lg-6">
        <div class="p-5">
          <div class="text-center">
            <h1 class="h4 text-gray-900 mb-4">Admin Area</h1>
          </div>
          <form class="user" [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="form-group">
              <label for="inputEmail" class="sr-only">Email Address</label>
              <input type="text" class="form-control form-control-user"
                     [ngClass]="loginForm.get('email').invalid && (loginForm.get('email').dirty || loginForm.get('email').touched) && loginForm.controls['email'].errors ? 'is-invalid' : ''"
                     id="inputEmail" placeholder="Email Address" formControlName="email">
              <div class="invalid-feedback"
                   *ngIf="loginForm.get('email').invalid && (loginForm.get('email').dirty || loginForm.get('email').touched)">
                <span *ngIf="loginForm.controls['email'].hasError('required')">This field is required</span>
                <span *ngIf="loginForm.controls['email'].hasError('email')">Please insert a valid email</span>
              </div>
            </div>
            <div class="form-group">
              <label for="inputPassword" class="sr-only">Password</label>
              <input type="password"
                     class="form-control form-control-user"
                     [ngClass]="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched) && loginForm.controls['password'].errors ? 'is-invalid' : ''"
                     id="inputPassword"
                     placeholder="Password"
                     formControlName="password">
              <div class="invalid-feedback"
                   *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)">
                <span *ngIf="loginForm.controls['password'].hasError('required')">This field is required</span>
              </div>
            </div>
            <button type="submit" class="btn btn-primary btn-user btn-block">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

