export const randomNum = () => {
  return Math.floor(Math.random() * (235 - 52 + 1) + 52);
}

export const randomRGB = () => {
  return `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;
}


export function downloadFile(url: string, fileName: string) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(this.response);
    var tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
}
