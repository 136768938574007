import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private storage: AngularFireStorage) {}

  // Uploads a file to the requested path and returns the download URL
  public async uploadFile(uploadPath: string, file: File): Promise<any> {
    return this.storage.upload(uploadPath, file).then(async (type) => {
      return await type.ref.getDownloadURL();
    });
  }

  public getDownloadURL(filePath: string): Observable<any> {
    return this.storage.ref(filePath).getDownloadURL();
  }

  public async deleteFile(path: string): Promise<void> {
    await this.storage.ref(path).delete().toPromise();
  }

  public async deleteFolder(path: string): Promise<void> {
    const storageRef = this.storage.ref(path);
    const list = await storageRef.listAll().toPromise();
    list.items.forEach(async (item) => {
      await item.delete();
    });
    const subFoldersPromises = list.prefixes.map(async (folderRef) => {
      await this.deleteFolder(folderRef.fullPath);
    });
    await Promise.all(subFoldersPromises);
  }
}
