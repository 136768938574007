import {Injectable} from '@angular/core';
import {BaseDbService} from './base-db.service';
import {ICondition, ITherapeuticArea} from '../models/therapeutic-area.model';
import firebase from 'firebase';
import {Observable, of} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {delay, map} from 'rxjs/operators';
import DocumentReference = firebase.firestore.DocumentReference;
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class TherapeuticAreaService {

  THERAPEUTIC_AREA_KEY = 'therapeuticAreas';
  CONDITION_KEY = 'conditions';

  private therapeuticAreas: ITherapeuticArea[] = [];


  constructor(private baseDBService: BaseDbService,
              private db: AngularFirestore) {
  }

  public async createTherapeuticArea(therapeuticArea: ITherapeuticArea): Promise<DocumentReference> {
    const doc = await this.baseDBService.createObject(therapeuticArea, this.THERAPEUTIC_AREA_KEY);
    this.baseDBService.updateLocalList(this.therapeuticAreas, therapeuticArea);
    return doc;
  }

  public async updateTherapeuticArea(therapeuticArea: ITherapeuticArea): Promise<void> {
    await this.baseDBService.updateObject(therapeuticArea, this.THERAPEUTIC_AREA_KEY);
    this.baseDBService.updateLocalList(this.therapeuticAreas, therapeuticArea);
  }

  public getTherapeuticAreaByID(id: string): Observable<ITherapeuticArea> {
    return this.db.collection(this.THERAPEUTIC_AREA_KEY).doc(id).get().pipe(map(d => d.data()));
  }

  public getTherapeuticAreas(): Observable<ITherapeuticArea[]> {
    if (this.therapeuticAreas.length > 0) {
      // Add a small delay so datatables has time to render, emulating a server call
      return of(this.therapeuticAreas).pipe(delay(100));
    } else {
      return this.db.collection(this.THERAPEUTIC_AREA_KEY).get().pipe(map(queryResult => {
        this.therapeuticAreas = queryResult.docs.map(d => d.data());
        return this.therapeuticAreas;
      }));
    }
  }


  getConditionsByTherapeuticAreaID(therapeuticAreaID: string) {
    return this.db.collection(this.CONDITION_KEY, ref => {
      return ref.where('therapeuticAreaID', '==', therapeuticAreaID)
    }).valueChanges();
  }

  public async createCondition(condition: ICondition): Promise<DocumentReference> {
    condition.medicalArtCount = 0;
    return await this.baseDBService.createObject(condition, this.CONDITION_KEY);
  }

  public async updateCondition(condition: ICondition): Promise<void> {
    await this.baseDBService.updateObject(condition, this.CONDITION_KEY);
  }

  async deleteCondition(condition: ICondition): Promise<void> {
    await this.baseDBService.deleteObject(condition, this.CONDITION_KEY);
  }

  public getConditionByID(id: string): Observable<ICondition> {
    return this.db.collection(this.CONDITION_KEY).doc(id).valueChanges();
  }

  public getConditions(): Observable<ICondition[]> {
    return this.db.collection(this.CONDITION_KEY).valueChanges();
  }

  async getTherapeuticAreasMapped() {
    const therapeuticAreas = await this.db.collection(this.THERAPEUTIC_AREA_KEY).get().toPromise()
      .then(query => query.docs)
      .then(docs => docs.map(d => d.data() as ITherapeuticArea));
    const conditions = await this.db.collection(this.CONDITION_KEY).get().toPromise()
      .then(query => query.docs)
      .then(docs => docs.map(d => d.data() as ICondition));
    for (let cond of conditions) {
      const ta = therapeuticAreas.find(ta => ta.id === cond.therapeuticAreaID);
      ta.conditions ? ta.conditions.push(cond) : (ta.conditions = [cond]);
    }
    return therapeuticAreas;
  }

  incrementConditionMedicalArtCounter(conditionID: string, n: number) {
    return this.db.collection(this.CONDITION_KEY).doc(conditionID)
      .update({medicalArtCount: FieldValue.increment(n)})
  }


}
