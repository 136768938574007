import { Component, OnInit } from '@angular/core';
import {AuthService} from '../services/auth.service';
import firebase from 'firebase';
import User = firebase.User;
import {UserRole} from '../models/user.model';
import {AlertService} from '../services/alert.service';
import {IAlert} from '../models/alert.model';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  user: User;
  userRoles: UserRole[] = [];

  alerts: IAlert[] = [];

  constructor(
    private authService: AuthService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.authService.user$.subscribe(user => {
      this.user = user;
      this.alertService.getUnreadAlerts(this.user.uid).subscribe(alerts => {
        this.alerts = alerts;
      });
    });
  }

  get UserRole() {
    return UserRole;
  }

  logout() {
    this.authService.logout();
  }

  markAlertAsUnread(alert: IAlert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
    this.alertService.markAlertAsRead(alert.id);
  }

  clearAllAlerts(){
    for(let a of this.alerts) {
      this.alertService.markAlertAsRead(a.id);
    }
    this.alerts = [];
  }

}
