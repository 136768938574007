import {Directive, HostBinding, Input} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Directive({
  selector: '[appFormInvalid]',
  host: {
    '[class.is-invalid]': 'form.invalid && (form.dirty || form.touched) && form.errors'
  }
})
export class FormInvalidDirective {
  @Input() form: AbstractControl;
}
