<h1 class="h3 text-gray-800" *ngIf="header">{{header}}</h1>

<form [formGroup]="form" class="pb-3" autocomplete="off">
  <hr>
  <ng-content>

  </ng-content>
  <hr>
  <div class="mt-3">
    <button type="button" id="cancel-save" class="btn btn-secondary margin-right-10" (click)="previousState()">
      <i class="fa fa-ban"></i><span> Cancel</span>
    </button>
    <button type="submit" id="save-entity" [disabled]="!canSaveForm()" class="btn btn-primary ml-1">
      <i class="fa fa-save"></i><span> Save</span>
    </button>
  </div>
</form>
