<div class="card">
  <div class="card-header" *ngIf="header">
    <h6 class="m-0 text-primary font-weight-bold">{{header}}</h6>
  </div>
  <div class="card-body">
    <ng-content></ng-content>
  </div>
</div>


