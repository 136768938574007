<div class="my-2">
  <label>{{ name }}</label>
  <br />
  <button class="btn btn-sm btn-primary" (click)="add()" type="button">
    Add {{ buttonText ? buttonText : name }}
  </button>
  <div class="row">
    <div
      class="col-12 col-lg-6 pt-3"
      *ngFor="let c of formArray.controls; let i = index"
    >
      <div class="card border-dark">
        <div class="card-header">
          <button
            class="btn btn-sm btn-danger float-right"
            type="button"
            (click)="remove(i)"
          >
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="card-body d-flex flex-wrap" style="gap: 8px">
          <ng-container *ngFor="let data of _controlData">
            <ng-container *ngIf="getControl(data, c) as control">
              <app-input-field
                [control]="control"
                [name]="data.name"
                [type]="$any(data.type)"
                [errors]="data.errors"
                *ngIf="data.type !== 'select'"
                class="flex-grow-1"
              ></app-input-field>
              <app-select-field
                [control]="control"
                [name]="data.name"
                [errors]="data.errors"
                *ngIf="data.type === 'select'"
                class="flex-grow-1"
              ></app-select-field>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
