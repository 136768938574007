import {Injectable} from '@angular/core';
import {BaseDbService} from './base-db.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {forkJoin, Observable} from 'rxjs';
import firebase from 'firebase';
import DocumentReference = firebase.firestore.DocumentReference;
import {
  IProduct,
  ICategory,
  IProductView,
  ICatalogue,
  IClientWork,
  IClient,
  IIncomingClientWork, IClientWorkView, IShopMetrics, IMedicalArt, IMedicalArtView
} from '../models/shop.module';
import {map, mergeMap, take} from 'rxjs/operators';
import {StorageService} from './storage.service';
import {TherapeuticAreaService} from "./therapeutic-area.service";

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  private PRODUCT_KEY = 'products';
  private CATEGORY_KEY = 'productCategories';
  private PRODUCT_VIEW_KEY = 'productViews';
  private CATALOGUE_KEY = 'catalogues';
  private CLIENT_KEY = 'clients';
  private CLIENT_WORKS_KEY = 'clientWorks';
  private INCOMING_CLIENT_WORKS_KEY = 'incomingClientWorks';
  private CLIENT_WORK_VIEW_KEY = 'clientWorkViews';
  private METRICS_KEY = 'shopMetrics';
  private MEDICAL_ART_KEY = 'medicalArt'
  private MEDICAL_ART_VIEWS_KEY = 'medicalArtViews';

  constructor(private baseDBService: BaseDbService,
              private db: AngularFirestore,
              private storageService: StorageService,
              private therapeuticAreaService: TherapeuticAreaService) {
  }

  // Products

  private getProductsUploadDirectory(productID: string, fileName: string): string {
    return `products/${productID}/${fileName}`;
  }

  private async uploadProductFiles(product, file?, psFile?, previewImage?): Promise<void> {
    const tasks = [];
    if (file) {
      if (file && product.filePath) {
        await this.storageService.deleteFile(product.filePath);
      }
      product.filePath = this.getProductsUploadDirectory(product.id, file.name);
      tasks.push(this.storageService.uploadFile(product.filePath, file).then(url => {
        product.fileURL = url;
      }));
    }
    if (psFile && product.hasPresentationSheet) {
      if (psFile && product.presentationSheetPath) {
        await this.storageService.deleteFile(product.presentationSheetPath);
      }
      product.presentationSheetPath = this.getProductsUploadDirectory(product.id, psFile.name);
      tasks.push(this.storageService.uploadFile(product.presentationSheetPath, psFile).then(url => {
        product.presentationSheetURL = url;
      }));
    }
    if (previewImage && product.hasCustomPreviewImage) {
      product.previewImagePath = this.getProductsUploadDirectory(product.id, previewImage.name);
      tasks.push(this.storageService.uploadFile(product.previewImagePath, previewImage).then(url => {
        product.previewImageURL = url;
      }));
    }
    await Promise.all(tasks);
  }

  public async createProduct(product: IProduct, file: File, psFile: File, previewImage: File): Promise<any> {
    product.id = this.db.createId();
    product.nameInsensitive = product.name.toLowerCase();
    product.tags = product.nameInsensitive.split(' ');
    product.mainCategoryName = await this.getCategoryByID(product.mainCategoryID).toPromise().then(r => r.name);
    product.subCategoryName = await this.getCategoryByID(product.subCategoryID).toPromise().then(r => r.name);
    await this.uploadProductFiles(product, file, psFile, previewImage);
    await this.baseDBService.createObjectWithExistingID(product, this.PRODUCT_KEY);
  }

  public async updateProduct(product: IProduct, file: File, psFile: File, previewImage: File): Promise<void> {
    product.nameInsensitive = product.name.toLowerCase();
    product.tags = product.nameInsensitive.split(' ');
    product.mainCategoryName = await this.getCategoryByID(product.mainCategoryID).toPromise().then(r => r.name);
    product.subCategoryName = await this.getCategoryByID(product.subCategoryID).toPromise().then(r => r.name);
    await this.uploadProductFiles(product, file, psFile, previewImage);
    await this.baseDBService.updateObject(product, this.PRODUCT_KEY);
  }

  public async deleteProduct(product: IProduct): Promise<void> {
    await this.baseDBService.deleteObject(product, this.PRODUCT_KEY);
  }

  public getProductByID(id: string): Observable<IProduct> {
    return this.db.collection(this.PRODUCT_KEY).doc(id).get().pipe(map(obj => obj.data()));
  }

  public getProductByIDTakeOne(id: string): Observable<IProduct> {
    return this.getProductByID(id).pipe(take(1));
  }

  public getProductsByCategoryID(subCategoryID: string): Observable<IProduct[]> {
    return this.db.collection(this.PRODUCT_KEY, ref => {
      return ref.where('subCategoryID', '==', subCategoryID);
    }).get().pipe(mergeMap(queryResult => {
      const products: IProduct[] = queryResult.docs.map(d => d.data());
      return this.therapeuticAreaService.getTherapeuticAreas().pipe(map(tas => {
        for (const p of products) {
          p.therapeuticArea = tas.find(ta => ta.id === p.therapeuticAreaID);
        }
        return products;
      }))
    }));
  }

  public getProducts(): Observable<IProduct[]> {
    return this.db.collection(this.PRODUCT_KEY)
      .get().pipe(mergeMap(queryResult => {
        return this.getAllCategories().pipe(map(categories => {
          const products = queryResult.docs.map(d => d.data()) as IProduct[];
          for (const p of products) {
            const mainCategory = categories.find(c => c.id === p.mainCategoryID);
            const subCategory = categories.find(c => c.id === p.subCategoryID);
            if (mainCategory && subCategory) {
              p.mainCategory = mainCategory;
              p.subCategory = subCategory;
            } else {
              // Something went wrong, remove product from list
              // TODO: Notify something??
              products.splice(products.indexOf(p), 1);
            }
          }
          return products;
        }));
      }));
  }

  public getProductsTakeOne(): Observable<IProduct[]> {
    return this.getProducts().pipe(take(1));
  }


  // Categories

  public createCategory(category: ICategory): Promise<DocumentReference> {
    return this.baseDBService.createObject(category, this.CATEGORY_KEY);
  }

  public updateCategory(category: ICategory): Promise<void> {
    return this.baseDBService.updateObject(category, this.CATEGORY_KEY);
  }

  public getCategoryByID(id: string): Observable<ICategory> {
    return this.db.collection(this.CATEGORY_KEY).doc(id).get().pipe(map(obj => obj.data()));
  }

  public getCategoryByIDTakeOne(id: string): Observable<ICategory> {
    return this.getCategoryByID(id).pipe(take(1));
  }

  public getMainCategories(): Observable<ICategory[]> {
    return this.db.collection<ICategory>(this.CATEGORY_KEY, ref => {
      return ref.where('isMainCategory', '==', true);
    }).get().pipe(map(queryResult => queryResult.docs.map(d => d.data())));
  }


  private getSubCategories(categoryID: string): Observable<ICategory[]> {
    return this.db.collection<ICategory>(this.CATEGORY_KEY, ref => {
      return ref.where('mainCategoryID', '==', categoryID);
    }).valueChanges().pipe(mergeMap(categories => {
      return this.getCategoryByID(categoryID).pipe(map(parent => {
        for (const cat of categories) {
          cat.mainCategory = parent;
        }
        return categories;
      }));
    }));
  }

  public getSubCategoriesTakeOne(categoryID: string): Observable<ICategory[]> {
    return this.getSubCategories(categoryID).pipe(take(1));
  }

  public getAllCategories(): Observable<ICategory[]> {
    return this.getMainCategories().pipe(take(1), mergeMap(mainCategories => {
      const getChildTasks = [];
      for (const r of mainCategories) {
        getChildTasks.push(
          this.getSubCategoriesTakeOne(r.id).pipe(map(children => {
            // Link them for easier usage
            for (const c of children) {
              c.mainCategory = r;
            }
            r.subCategories = children;
            // Add children to return array
            mainCategories.push(...children);
          }))
        );
      }
      return forkJoin(getChildTasks).pipe(map(v => {
        return mainCategories;
      }));
    }));
  }

  async deleteCategory(category: ICategory) {
    if (category.isMainCategory) {
      const subCategories = await this.getSubCategories(category.id).pipe(take(1)).toPromise();
      for (const s of subCategories) {
        await this.deleteFilesInSubCategory(s.id);
        await this.baseDBService.deleteObject(s, this.CATEGORY_KEY);
      }
    } else {
      await this.deleteFilesInSubCategory(category.id);
    }
    await this.baseDBService.deleteObject(category, this.CATEGORY_KEY);
  }

  private async deleteFilesInSubCategory(subCategoryID: string) {
    const products = await this.getProductsByCategoryID(subCategoryID).pipe(take(1)).toPromise();
    for (const p of products) {
      await this.deleteProduct(p);
    }
  }


  // Returns the last 500 product views of a given user
  getProductViewsByUserID(userID: string): Observable<IProductView[]> {
    return this.db.collection(this.PRODUCT_VIEW_KEY, ref => {
      return ref.where('userID', '==', userID).orderBy('createdOn', 'desc').limit(500)
    }).get().pipe(map(query => {
      return query.docs.map(d => d.data());
    }))
  }

  // Returns the last 500 product views
  getProductViews(): Observable<IProductView[]> {
    return this.db.collection(this.PRODUCT_VIEW_KEY, ref => {
      return ref.orderBy('createdOn', 'desc').limit(500)
    }).get().pipe(map(query => {
      return query.docs.map(d => d.data());
    }))
  }

  getCatalogues() {
    return this.db.collection(this.CATALOGUE_KEY).get()
      .pipe(map(query => query.docs.map(d => d.data())));
  }

  getCatalogueByID(catalogueID: string): Observable<ICatalogue> {
    return this.db.collection(this.CATALOGUE_KEY).doc(catalogueID).get().pipe(map(d => d.data()));
  }

  private getCataloguesUploadDirectory(catalogueID: string, fileName: string): string {
    return `catalogues/${catalogueID}/${fileName}`;
  }

  async createCatalogue(catalogue: ICatalogue, file: File): Promise<void> {
    catalogue.id = this.db.createId();
    catalogue.filePath = this.getCataloguesUploadDirectory(catalogue.id, file.name);
    catalogue.fileURL = await this.storageService.uploadFile(catalogue.filePath, file);
    catalogue.nameInsensitive = catalogue.name.toLowerCase();
    catalogue.tags = catalogue.nameInsensitive.split(' ');
    await this.baseDBService.createObjectWithExistingID(catalogue, this.CATALOGUE_KEY);
  }

  async updateCatalogue(catalogue: ICatalogue, file?: File): Promise<void> {
    if (file) {
      catalogue.filePath = this.getCataloguesUploadDirectory(catalogue.id, file.name);
      catalogue.fileURL = await this.storageService.uploadFile(catalogue.filePath, file);
    }
    catalogue.nameInsensitive = catalogue.name.toLowerCase();
    catalogue.tags = catalogue.nameInsensitive.split(' ');
    await this.baseDBService.updateObject(catalogue, this.CATALOGUE_KEY);
  }

  async deleteCatalogue(catalogue: ICatalogue): Promise<void> {
    await this.baseDBService.deleteObject(catalogue, this.CATALOGUE_KEY);
  }

  async createClient(client: IClient) {
    return this.baseDBService.createObject(client, this.CLIENT_KEY);
  }

  async updateClient(client: IClient): Promise<void> {
    await this.baseDBService.updateObject(client, this.CLIENT_KEY);
  }

  async deleteClient(client: IClient): Promise<void> {
    const clientWorks = await this.getClientWorksByClientID(client.id).toPromise();
    for (const cW of clientWorks) {
      await this.deleteClientWork(cW);
    }
    await this.baseDBService.deleteObject(client, this.CLIENT_KEY);
  }

  getClients(): Observable<IClient[]> {
    return this.db.collection(this.CLIENT_KEY).get()
      .pipe(map(query => query.docs.map(d => d.data())));
  }

  getClientByID(clientID: string): Observable<IClient> {
    return this.db.collection(this.CLIENT_KEY).doc(clientID).get()
      .pipe(map(d => d.data()));
  }

  private getClientWorksUploadDirectory(clientWorkID: string, fileName: string): string {
    return `clientWorks/${clientWorkID}/${fileName}`;
  }

  async handleClientWorkUploads(clientWork: IClientWork, file?: File, previewImage?: File) {
    if (file) {
      clientWork.filePath = this.getClientWorksUploadDirectory(clientWork.id, file.name);
      clientWork.fileURL = await this.storageService.uploadFile(clientWork.filePath, file);
    }
    if (previewImage && clientWork.hasCustomPreviewImage) {
      clientWork.previewImagePath = this.getClientWorksUploadDirectory(clientWork.id, previewImage.name);
      clientWork.previewImageURL = await this.storageService.uploadFile(clientWork.previewImagePath, previewImage);
    }
  }

  async createClientWork(clientWork: IClientWork, file: File, previewImage: File): Promise<void> {
    clientWork.id = this.db.createId();
    await this.handleClientWorkUploads(clientWork, file, previewImage);
    clientWork.nameInsensitive = clientWork.name.toLowerCase();
    clientWork.tags = clientWork.nameInsensitive.split(' ');
    clientWork.tags.push('' + clientWork.year);
    clientWork.clientName = await this.getClientByID(clientWork.clientID).toPromise().then(r => r.name);
    await this.baseDBService.createObjectWithExistingID(clientWork, this.CLIENT_WORKS_KEY);
  }

  async updateClientWork(clientWork: IClientWork, file: File, previewImage: File): Promise<void> {
    await this.handleClientWorkUploads(clientWork, file, previewImage);
    clientWork.nameInsensitive = clientWork.name.toLowerCase();
    clientWork.tags = clientWork.nameInsensitive.split(' ');
    clientWork.tags.push('' + clientWork.year);
    clientWork.clientName = await this.getClientByID(clientWork.clientID).toPromise().then(r => r.name);
    await this.baseDBService.updateObject(clientWork, this.CLIENT_WORKS_KEY);
  }

  async deleteClientWork(clientWork: IClientWork): Promise<void> {
    await this.baseDBService.deleteObject(clientWork, this.CLIENT_WORKS_KEY);
  }

  getClientWorksByClientID(clientID: string): Observable<IClientWork[]> {
    return this.db.collection(this.CLIENT_WORKS_KEY, ref => {
      return ref.where('clientID', '==', clientID)
    }).get().pipe(map(query => query.docs.map(d => d.data())));
  }

  getClientWorkByID(clientWorkID: string): Observable<IClientWork> {
    return this.db.collection(this.CLIENT_WORKS_KEY).doc(clientWorkID).get()
      .pipe(map(d => d.data()));
  }

  async createIncomingClientWork(work: IIncomingClientWork) {
    await this.baseDBService.createObject(work, this.INCOMING_CLIENT_WORKS_KEY);
  }

  async updateIncomingClientWork(work: IIncomingClientWork) {
    await this.baseDBService.updateObject(work, this.INCOMING_CLIENT_WORKS_KEY);
  }

  async deleteIncomingClientWork(work: IIncomingClientWork) {
    await this.baseDBService.deleteObject(work, this.INCOMING_CLIENT_WORKS_KEY);
  }

  getIncomingClientWorks(): Observable<IIncomingClientWork[]> {
    return this.db.collection(this.INCOMING_CLIENT_WORKS_KEY).get().pipe(map(query => query.docs.map(d => d.data())));
  }

  getIncomingClientWorkByID(id: string): Observable<IIncomingClientWork> {
    return this.db.collection(this.INCOMING_CLIENT_WORKS_KEY).doc(id).get().pipe(map(doc => doc.data()));
  }

  // Returns the last 500 client work views of a given user
  getClientWorkViewsByUserID(userID: string): Observable<IClientWorkView[]> {
    return this.db.collection(this.CLIENT_WORK_VIEW_KEY, ref => {
      return ref.where('userID', '==', userID).orderBy('createdOn', 'desc').limit(500)
    }).get().pipe(map(query => {
      return query.docs.map(d => d.data());
    }))
  }

  getShopMetrics(): Observable<IShopMetrics> {
    return this.db.collection(this.METRICS_KEY, ref => {
      return ref.orderBy('createdOn', 'desc').limit(1)
    }).get().pipe(map(query => {
      return query.docs[0].data();
    }))
  }


  getMedicalArtFiles() {
    return this.db.collection<IMedicalArt>(this.MEDICAL_ART_KEY).valueChanges();
  }

  async createMedicalArt(medicalArt: IMedicalArt, file: File): Promise<void> {
    medicalArt.id = this.db.createId();
    await this.handleMedicalArtUploads(medicalArt, file);
    medicalArt.nameInsensitive = medicalArt.name.toLowerCase();
    await this.baseDBService.createObjectWithExistingID(medicalArt, this.MEDICAL_ART_KEY);
    for(let id of medicalArt.conditionIDs) {
      await this.therapeuticAreaService.incrementConditionMedicalArtCounter(id, 1);
    }
  }

  async updateMedicalArt(medicalArt: IMedicalArt, file: File): Promise<void> {
    await this.handleMedicalArtUploads(medicalArt, file);
    medicalArt.nameInsensitive = medicalArt.name.toLowerCase();
    const oldMedicalArt = await this.getMedicalArtByID(medicalArt.id).pipe(take(1)).toPromise();
    const deletedConditions = oldMedicalArt.conditionIDs.filter(id => medicalArt.conditionIDs.indexOf(id) === -1);
    const addedConditions = medicalArt.conditionIDs.filter(id => oldMedicalArt.conditionIDs.indexOf(id) === -1);
    for(let id of deletedConditions) {
      await this.therapeuticAreaService.incrementConditionMedicalArtCounter(id, -1);
    }
    for(let id of addedConditions) {
      await this.therapeuticAreaService.incrementConditionMedicalArtCounter(id, 1);
    }
    await this.baseDBService.updateObject(medicalArt, this.MEDICAL_ART_KEY);
  }

  async deleteMedicalArt(medicalArt: IMedicalArt): Promise<void> {
    await this.baseDBService.deleteObject(medicalArt, this.MEDICAL_ART_KEY);
    for(let id of medicalArt.conditionIDs) {
      await this.therapeuticAreaService.incrementConditionMedicalArtCounter(id, -1);
    }
  }

  async handleMedicalArtUploads(medicalArt: IMedicalArt, file?: File) {
    if (file) {
      const path = this.getMedicalArtUploadsDirectory(medicalArt.id, file.name);
      medicalArt.file = {
        path,
        url: await this.storageService.uploadFile(path, file)
      };
    }
  }


  private getMedicalArtUploadsDirectory(medicalArtID: string, fileName: string): string {
    return `${this.MEDICAL_ART_KEY}/${medicalArtID}/${fileName}`;
  }

  getMedicalArtByID(id: string) {
    return this.db.collection<IMedicalArt>(this.MEDICAL_ART_KEY).doc(id).valueChanges();
  }

  // Returns the last 500 medical art views of a given user
  getMedicalArtViewsByUserID(userID: string): Observable<IMedicalArtView[]> {
    return this.db.collection(this.MEDICAL_ART_VIEWS_KEY, ref => {
      return ref.where('userID', '==', userID).orderBy('createdOn', 'desc').limit(500)
    }).get().pipe(map(query => {
      return query.docs.map(d => d.data());
    }))
  }

}
