import {Component, Input, OnInit} from '@angular/core';
import {
  FormArray,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import {IControlData} from "../array-input/array-input.component";

@Component({
  selector: 'app-translatable-input[name][formArray]',
  templateUrl: './translatable-input.component.html',
  styleUrls: ['./translatable-input.component.css']
})
export class TranslatableInputComponent implements OnInit {
  @Input() set formArray(array: FormArray) {
    this._formArray = array;
    this._formArray.setValidators([this.uniqueLanguageValidator(), this._formArray.validator]);
  };
  @Input() name: string;
  @Input() type: 'file' | 'text' = 'text';

  _controlData: IControlData[];
  _formArray: FormArray;

  constructor() { }

  ngOnInit(): void {
    this._controlData = [
      {
        name: 'Language Code',
        controlKey: 'language',
        type: 'text',
      },
      {
        name: this.type.substring(0,1).toUpperCase() + this.type.substring(1),
        controlKey: this.type,
        type: this.type,
      }
    ];
  }

  uniqueLanguageValidator(): ValidatorFn {
    return (array: FormArray) : ValidationErrors | null => {
      let values = [];
      for(let c of array.getRawValue()) {
        if(values.includes(c.language)) {
          return {languageDuplicated:true};
        }
        values.push(c.language);
      }
      return null;
    }
  }


}
