import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {IAlert} from '../models/alert.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  ALERT_KEY = 'alerts';

  constructor(
    private db: AngularFirestore,
  ) { }

  getUnreadAlerts(userID: string): Observable<IAlert[]> {
    return this.db.collection(this.ALERT_KEY, ref => {
      return ref.where('read', '==', false).where('userID', '==', userID)
    }).valueChanges();
  }

  async markAlertAsRead(id: string): Promise<void> {
    await this.db.collection(this.ALERT_KEY).doc(id).update({
      read: true
    });
  }
}
